<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <div class="card-body">
          <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/user-admin'}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h3 class="text-primary mb-5 text-center">Display User Admin</h3>
              <div class="form-group">
                <label for="name"
                  >Email<span class="text-danger"></span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="email" readonly
                  v-model="email"
                />
              </div>
              <div class="form-group">
            
                <label for="name"
                  >Role<span class="text-danger"></span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="role" readonly
                  v-model="role"
                />
              </div>
              <div class="form-group">
                <label for="status"
                  >Group<span class="text-danger"></span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="group" readonly
                  v-model="this.group"
                />
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  :unchecked-value="false"
                  class="form-check-input"
                  readonly
                  id="is_active"
                  v-model="isActive"
                />
                <label class="form-check-label" for="is_active"
                  >Is Active</label
                >
              </div>
              <div v-if="isBlock" class="form-group" >
                <div class="v"> {{timerOutput}}</div>
              </div>
              
              <div v-if="roleNumber==SUPER_ADMIN && !isBlock" class="form-group" >
                <label for="status"
                  >Secret Key<span class="text-danger"></span> :</label
                >
                <button
                type="submit"
                class="btn btn-block btn-primary"
                @click="getInputPassword(1)"
              >
                <i class="fa fa-eye"></i>
                
                Show Secret Key
              </button>
              </div>
            </div>

            <div class="col-sm-3"></div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from "../../store/constant";
const second=30;
const jumlah = 3;
export default {
  name: 'DisplayUserAdmin',
  data() {
    return {
      Form: {},
      email: '',
      isLoading: false,
      isLoadingSearch: false,
      role: null,
      optionsRole: null,
      group: null,
      roleNumber: null,
      userId:null,
      optionsGroup: null,
      isActive: true,
      groupDisabled: null,
      countPassword:null,
      message:null,
      isBlock:false,
      interval:null,
      countDownToTime : null,
      timerOutput:  null,
      SUPER_ADMIN: constant.ROLES.SUPER_ADMIN,
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.admin.item,
      successMessage: (state) => state.admin.successMessage,
      errorMessage: (state) => state.admin.errorMessage,
      isError: (state) => state.admin.isError,
    }),
    buttonLabel() {
      return this.showPassword ? 'Show' : 'Hide';
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Display User Admin | Content Management System Ebooks Gramedia.com';
      },
    },
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getUserAdmin();
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
  },
  created: function() {
    this.getLoginUser();
    const userID = this.$route.params.id;
    this.fetchUserAdminById({ id: userID });
  },
  mounted() {
    let localIsBlock = localStorage.getItem("isBlock");
    if (localIsBlock){
      this.isBlock=localIsBlock;
      let timeEndBlockEnd = localStorage.getItem("timeBlockEnd");
      if(!timeEndBlockEnd){
        let now = null;
        now = new Date();
        this.countDownToTime = now.setSeconds(now.getSeconds() + second);
      } else{
        // check if refresh
        this.countDownToTime =  timeEndBlockEnd;
      }
      this.interval = setInterval(() => { this.startTimer() }, 1000);
    }else{
      this.isBlock=false;
    }
    // this.timerBlock(5);
  },
  methods: {
    ...mapActions('admin', ['fetchUserAdminById','showSecret']),

    timerBlock(seccond) {
      this.interval = setInterval(() => {
        seccond -= 1;
        if (seccond <= 0) {
          clearInterval(this.interval);
          alert('done')
        }
      }, 1000);
    },

    async getInputPassword(counter = 1, text = null) {
      if (counter == jumlah+1) return;
      const swalQueueStep = this.baseAlertMessage();
      if(!text){
        text = 'Input Your Password';
      }
      let confirmPassword = await swalQueueStep.fire({
        title: ` ${text}  Try  ( ${counter} / ${jumlah} )`,
        input: 'password',
        currentProgressStep: counter,
        preConfirm: (login) => {
          this.password = login;
          return login;
        },
      });
      if (!confirmPassword.isConfirmed) return;
      const password = confirmPassword.value;
      const response = await this.showSecret({
        userId: this.userId,
        userEmail: this.item.email,
        password
      });
      let {
        result,
        message,
        secretKey,
        secretKeyIsNotExist,
      } = response;
      if(counter == jumlah&&result==false) {
        this.showMessageAlert('You Have Been Input Password More Than 3 Times, Your Account is Locked for 30 Seconds');
        let now = new Date();
        let nowPlusSecond = now.setSeconds(now.getSeconds() + second);
        this.countDownToTime= nowPlusSecond;
        localStorage.setItem('isBlock', this.isBlock);
        localStorage.setItem("timeBlockEnd",nowPlusSecond);
        localStorage.setItem("timeBlockStart",now);
        this.interval = setInterval(() => { this.startTimer() }, 30);
      }
      if (result == false && counter <= jumlah && !secretKeyIsNotExist) {
        return this.getInputPassword(counter += 1, message);
      }
      if (secretKeyIsNotExist) {
        return this.showMessageAlert(message);
      }
      if (result) {
        this.showMessageAlert(`<h5 style = 'color:red'> Do Not Give This Key to Others ${secretKey} </h5> <br/> `);
      }
    }, showMessageAlert(message) {
      this.baseAlertMessage().fire({
        title: `${message}`,
      })
    },baseAlertMessage() {
      return this.$swal.mixin({
        customClass: 'swal-wide',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
      });
    },
    async getUserAdmin() {
      this.userId = this.$route.params.id;
      const data = this.item;
      if(data){
        this.email = data.email;
        this.role = data.roles.name;
        this.group = data.group;
        this.isActive = data.is_active;
      }
    },startTimer: function() {
      const timeNow = new Date().getTime();
      let timeSessionEnd = this.countDownToTime;
      const timeDifference = timeSessionEnd - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingSeconds =Math.floor(remainderDifferenceInSeconds);
      if(remainingSeconds==0){
        localStorage.removeItem("isBlock");
        localStorage.removeItem("timeBlockStart");
        localStorage.removeItem("timeBlockEnd");
        this.isBlock = false;
        clearInterval(this.interval);
        return  null;
      }else if (remainingSeconds>0){
        this.isBlock= true;
        this.timerOutput = 'Your Account Blocked, Please Try Again In : '+ remainingSeconds + ' Seconds';
      } else if(remainingSeconds<0){
        localStorage.removeItem("isBlock");
        localStorage.removeItem("timeBlockStart");
        localStorage.removeItem("timeBlockEnd");
        clearInterval(this.interval);
        return null;
      }
    },
    async getLoginUser(){
      let auth = localStorage.getItem("session_auth");
      auth= JSON.parse(auth);
      const role_id = auth.role_id;
      this.roleNumber = role_id;
    },
   alertMsg(msg, type) {
    this.$swal({
      icon: type,
      toast: true,
      position: 'top-end',
      title: msg,
      timer: 10000,
      text: this.message,
    });
  },
  },
};
</script>

<style>
.swal-wide{
    width:750px !important;
}
</style>
